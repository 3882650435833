import w1 from './img/purifier/1.jpg';
import w2 from './img/purifier/2.jpg';
import w3 from './img/purifier/3.jpg';
import w4 from './img/purifier/4.jpg';
import w5 from './img/purifier/5.jpg';
import w6 from './img/purifier/6.jpg';
import R20 from './img/camera/R20.png';
import carame1 from './img/camera/1.png';
import carame2 from './img/camera/2.png';
import carame3 from './img/camera/3.png';
import carame4 from './img/camera/4.png';
import carame5 from './img/camera/5.png';
import bath1 from './img/bath/1.png';
import bath2 from './img/bath/2.jpg';
import bath3 from './img/bath/3.jpg';
import bath4 from './img/bath/4.jpg';
import bath5 from './img/bath/5.jpg';
import category1 from './img/category-1.jpg';
import category2 from './img/category-2.jpg';
import category3 from './img/category-3.jpg';


const productData = [
    {
        title: 'water purifier',
        category: 1001,
        pic:category1,
        child: [
            {
                title: 'Heating Integrated Water Purifier',
                pic: w1,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '5.3 kg',
                    size: '40cm*45cm*39cm',
                    level: 5
                },
                id: '100000'
            },
            {
                title: 'Multifunctional High-End Water Purifier',
                pic: w2,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '6.3 kg',
                    size: '14cm*45cm*70cm',
                    level: 5
                },
                id: '100001'
            },
            {
                title: 'Data Control Water Purifier',
                pic: w3,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '5.3 kg',
                    size: '38cm*45cm*39cm',
                    level: 5
                },
                id: '100002'
            },
            {
                title: 'Stainless Steel Water Purifier',
                pic: w4,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '4.8 kg',
                    size: '15cm*48cm*39cm',
                    level: 5
                },
                id: '100003'
            },
            {
                title: '6 Stages Water Prifier',
                pic: w5,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '5.3 kg',
                    size: '14cm*56cm*39cm',
                    level: 5
                },
                id: '100004'
            },
            {
                title: '3 Stage Ultrafiltration Water Purifier',
                pic: w6,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.3 kg',
                    size: '14cm*45cm*39cm',
                    level: 5
                },
                id: '100005'
            },
        ]
    },
    {
        title: 'CCTV Camera',
        category: 1002,
        pic:category2,
        child: [
            {
                title: 'Ball Machine CCTV Camera',
                pic: carame1,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '2.5 kg',
                    size: '18cm*18cm*39cm',
                    level: 5
                },
                id: '200000'
            },
            {
                title: 'Intelligent Full-Color Night Vision CCTV Camera',
                pic: carame2,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.1 kg',
                    size: '18cm*18cm*39cm',
                    level: 5
                },
                id: '200001'
            },
            {
                title: 'Multi Binocular Panoramic CCTV Camera',
                pic: carame3,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.5 kg',
                    size: '17cm*17cm*42cm',
                    level: 5
                },
                id: '200002'
            },
            {
                title: 'Dual Camera Outdoor CCTV Camera',
                pic: carame4,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.7 kg',
                    size: '17cm*17cm*42cm',
                    level: 5
                },
                id: '200003'
            },
            {
                title: 'Ultra clear binocular zoom CCTV Camera',
                pic: carame5,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.4 kg',
                    size: '16cm*16cm*41cm',
                    level: 5
                } ,
                id: '200004'
            },
            {
                title: 'R20',
                pic: R20,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.1 kg',
                    size: '17cm*16cm*40cm',
                    level: 5
                },
                id: '200005' 
            },
        ]
    },
    {
        title: 'Bathroom Related',
        category: 1003,
        pic:category3,
        child: [
            {
                title: 'Bathroom Shower All Copper Shower Set',
                pic: bath1,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '2.8 kg',
                    size: '8cm*16cm*39cm',
                    level: 5
                } ,
                id: '300000'
            },
            {
                title: 'Household High-End Domestic Water Heater',
                pic: bath2,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '8.6 kg',
                    size: '25cm*36cm*62cm',
                    level: 5
                } ,
                id: '300001'
            },
            {
                title: 'Stainless Steel Multi-Function Shower Complete Set',
                pic: bath3,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.1 kg',
                    size: '25cm*36cm*62cm',
                    level: 5
                } ,
                id: '300002'
            },
            {
                title: 'Upgrade Multi-Functional Shower Complete Set',
                pic: bath4,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.3 kg',
                    size: '10cm*15cm*45cm',
                    level: 5
                } ,
                id: '300003'
            },
            {
                title: 'Stainless Steel Deluxe Shower Complete Set',
                pic: bath5,
                img: [],
                describe: {
                    region: 'Made In US',
                    weight: '3.3 kg',
                    size: '10cm*15cm*45cm',
                    level: 5
                } ,
                id: '300004'
            }
        ]
    }
]

export default productData;