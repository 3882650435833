import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Image } from 'antd';
import './index.less';
import { useState } from 'react';
import mallTxt from './img/mallTxt.png';

const items = [
  {
    label: 'Introduction',
    key: 'introduce',
    icon: '',
  },
  // {
  //   label: 'News Center',
  //   key: 'news',
  //   icon: '',
  // },
  {
    label: 'Product List',
    key: 'productlist',
    icon: '',
    disabled: false,
  },
  {
    label: 'Our Factory',
    key: 'factory_pc',
    icon: '',
  },
  {
    label: 'Contact Us',
    key: 'contact',
    icon: '',
  },
  {
    label: 'Shopping Mall',
    key: 'shopping',
    icon: '',
    disabled: false,
  },
];
const MenuList = () => {
  const [current, setCurrent] = useState('');
  // jump to view by id
  const scroToAnchor = (anchor) => {
    if( anchor == 'shopping' ) {
      window.open('https://ph.xiapibuy.com/trouwids.ph?categoryId=100010&entryPoint=ShopByPDP&itemId=18156948690');
    }
    if (anchor) {
      let anchorElement = document.getElementById(anchor);
      if (anchorElement) {
        anchorElement.scrollIntoView({block:'start', behavior: 'smooth'});
      }
    }
  }
  const onClick = (e) => {
    let key = e.key || e.target.getAttribute('w_key') || '';
    setCurrent(key);
    scroToAnchor(key);
  };
  
  // return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} style={{
  //   borderBottom: 0,
  //   lineHeight: '12vh',
  //   fontSize: '2.6vh',
  //   height: '12vh',
  //   background: 'none',
  //   fontWeight: 500,
  //   paddingLeft: 10
  // }} />;
  return (
    <div style={{ paddingTop: '42px' }}>
      {
        items.map( (it) => {
          return <div style={{ float: 'left', marginLeft: '20px'}}>
            {
                it.key === 'shopping' ? <Image src={ mallTxt } width={200} style={{ marginTop: '-79px', cursor: 'pointer' }} preview={false} onClick={ () => { onClick({key: 'shopping'}); }}/> : <div 
                style={{ fontSize: '28px', float: 'left', cursor: 'pointer', fontWeight: it.key === 'shopping' ? 600 : 400, fontStyle: it.key === 'shopping' ? 'italic': 'normal'}} 
                w_key={it.key || ''} 
                onClick={(e) => { onClick(e); }}
            >
              {it.label} 
            </div>          
            }
            
            {/* { it.key === 'contact' ? '' : <div style={{ color: 'Silver' , fontSize: '20px', float: 'left', marginTop: '4px', marginLeft: '2px'}}>|</div> } */}
          </div>
        })
      }
    </div>
  )
};
export default MenuList;