import React from "react";
import { Link } from 'react-router-dom';
import productData from './data.js';
import { Divider, Card, List, Image, Row, Col, Button } from 'antd';

import './index.less';
import cart_2 from './img/cart_2.png';

export default class ProductList extends React.Component {
    // 1001：净水器；1002：监控；1003：淋浴
    goMall = (e) => {
        let key = e.target.getAttribute('w_key') || '';
        if(key == 1001 || key >= 100000 && key < 200000) {
            window.open('https://ph.xiapibuy.com/trouwids.ph?shopCollection=158983908#product_list');
        } else if (key == 1002 || key >= 200000 && key < 300000) {
            window.open('https://ph.xiapibuy.com/trouwids.ph?shopCollection=246046931#product_list');
        }
    }
    render() {
        return (
            <div className="productlist" id="productlist">
                <Divider children={'Product List'} className='line-title' />
                {productData.map((item) => {
                    return (<div className=''>
                        <img src={item.pic} width='100%' w_key={item.category} onClick={this.goMall}/>
                        <List
                            grid={{
                                gutter: 16,
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 3,
                                xl: 3,
                                xxl: 4,
                            }}
                            style={{
                                marginTop: '20px',
                            }}
                            pagination='true'
                            dataSource={item.child}
                            renderItem={(item) => (
                                // <Link to={`/proDetail?id=${ item.id }`} key={ item.id }>
                                <Link to={``} key={ item.id }>
                                    <List.Item onClick={this.goMall}>
                                        <Card title={item.title}>
                                            <Row>
                                                <Col span={12}>
                                                    <img src={item.pic} style={{ textAlign: 'left' }} width={'100%'}/>
                                                </Col>
                                                <Col span={12} style={{ padding: '10px', fontWeight: 500 }}>
                                                    <div>{item.describe.region}</div>
                                                    <div>Weight : {item.describe.weight}</div>
                                                    <div>Size : {item.describe.size}</div>
                                                    <div>Level : {item.describe.level}</div>
                                                    <div style={{ marginTop: '50px', width: '100%', textAlign: 'right' }}>
                                                        <button className="buy-btn" w_key={item.id || ''} onClick={this.goMall}>
                                                            <img src={cart_2} width={20} style={{ marginRight: '5px', float: 'left' }} w_key={item.id || ''} onClick={this.goMall}/>
                                                            <div style={{ marginTop: '4px', float: 'left' }} w_key={item.id || ''} onClick={this.goMall}>>> BUY NOW</div>
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                </Link>
                            )}
                        />
                    </div>
                    )
                })}
            </div>
        )
    }
}